import {
  AutofillOptions,
  AutofillRetrieveResponse,
  AutofillSuggestionResponse,
  Evented,
  MapboxAutofill,
  SearchSession
} from '@mapbox/search-js-core';

import { MapboxSearchListbox } from './components/MapboxSearchListbox';
import { MapboxHTMLEvent } from './MapboxHTMLEvent';
import { Theme } from './theme';
import { deepEquals } from './utils';
import { fillFormWithFeature, findAddressInputs } from './utils/autofill';
import { tryConfirmBrowserAutofill } from './utils/confirmation';

import { config } from './config';

import { initDetectBrowserAutofill } from './utils/detect_browser_autofill';
import { AddressConfirmOptions } from './components/MapboxAddressConfirmation';
import { PopoverOptions } from './utils/popover';

/**
 * @class AutofillInstance
 */
export class AutofillInstance {
  #input: HTMLInputElement;
  #collection: AutofillCollectionType;

  listbox = new MapboxSearchListbox();

  constructor(
    collection: AutofillCollectionType,
    input: HTMLInputElement,
    autofillRef: MapboxAutofill
  ) {
    this.#input = input;
    this.#collection = collection;
    this.listbox.input = this.#input;

    // Bind the listbox to the session.
    this.listbox.session = new SearchSession(autofillRef);
    this.listbox.session.sessionToken = config.autofillSessionToken;

    this.listbox.addEventListener('suggest', this.#handleSuggest);
    this.listbox.addEventListener('suggesterror', this.#handleSuggestError);
    this.listbox.addEventListener('retrieve', this.#handleRetrieve);

    document.body.appendChild(this.listbox);
  }

  remove(): void {
    this.listbox.remove();
    this.listbox.removeEventListener('suggest', this.#handleSuggest);
    this.listbox.removeEventListener('suggesterror', this.#handleSuggestError);
    this.listbox.removeEventListener('retrieve', this.#handleRetrieve);
  }

  #handleSuggest = (e: MapboxHTMLEvent<AutofillSuggestionResponse>): void => {
    // Manually bubble up the event.
    this.#collection.fire('suggest', e.clone(this.#input));
  };

  #handleSuggestError = (e: MapboxHTMLEvent<Error>): void => {
    // Manually bubble up the event.
    this.#collection.fire('suggesterror', e.clone(this.#input));
  };

  #handleRetrieve = (e: MapboxHTMLEvent<AutofillRetrieveResponse>): void => {
    // Manually bubble up the event.
    this.#collection.fire('retrieve', e.clone(this.#input));

    if (!this.#input) {
      return;
    }

    const featureCollection = e.detail;
    if (
      !featureCollection ||
      !featureCollection.features ||
      !featureCollection.features.length
    ) {
      return;
    }

    fillFormWithFeature(featureCollection.features[0], this.#input);
  };
}

/**
 * @typedef AutofillCollectionOptions
 */
export interface AutofillCollectionOptions {
  /**
   * The [Mapbox access token](https://docs.mapbox.com/help/glossary/access-token/) to use for all requests.
   */
  accessToken?: string;
  /**
   * Options to pass to the underlying {@link MapboxAutofill} interface.
   */
  options?: Partial<AutofillOptions>;
  /**
   * The {@link Theme} to use for styling the autofill component.
   */
  theme?: Theme;
  /**
   * The {@link PopoverOptions} to define popover positioning.
   */
  popoverOptions?: Partial<PopoverOptions>;
  /**
   * If true, forms autofilled by the browser will prompt the
   * {@link confirmAddress} dialog for user confirmation.
   * An {@link AddressConfirmOptions} object can also be passed
   * to prompt {@link confirmAddress} with custom options.
   * Defaults to false.
   */
  confirmOnBrowserAutofill?: boolean | AddressConfirmOptions;
}

interface EventTypes<AutofillSuggestionResponse, AutofillRetrieveResponse> {
  /**
   * Fired when the user is typing in the input and provides a list of suggestions.
   *
   * The underlying response from {@link MapboxAutofill} is passed as the event's detail,
   * while the responsible input is passed as the event's target.
   *
   * @event suggest
   * @instance
   * @memberof AutofillCollection
   * @type {AutofillSuggestionResponse}
   * @example
   * ```typescript
   * collection.addEventListener('suggest', (event) => {
   *   const suggestions = event.detail.suggestions;
   *   const inputEl = event.target;
   *   // ...
   * });
   * ```
   */
  suggest: MapboxHTMLEvent<AutofillSuggestionResponse>;
  /**
   * Fired when {@link MapboxAutofill} has errored providing a list of suggestions.
   *
   * The underlying error is passed as the event's detail,
   * while the responsible input is passed as the event's target.
   *
   * @event suggesterror
   * @instance
   * @memberof AutofillCollection
   * @type {Error}
   * @example
   * ```typescript
   * collection.addEventListener('suggesterror', (event) => {
   *   const error = event.detail;
   *   const inputEl = event.target;
   *   // ...
   * });
   * ```
   */
  suggesterror: MapboxHTMLEvent<Error>;
  /**
   * Fired when the user has selected a suggestion, before the form is autofilled.
   *
   * The underlying response from {@link MapboxAutofill} is passed as the event's detail,
   * while the responsible input is passed as the event's target.
   *
   * @event retrieve
   * @instance
   * @memberof AutofillCollection
   * @type {AutofillRetrieveResponse}
   * @example
   * ```typescript
   * autofill.addEventListener('retrieve', (event) => {
   *   const featureCollection = event.detail;
   *   const inputEl = event.target;
   *   // ...
   * });
   * ```
   */
  retrieve: MapboxHTMLEvent<AutofillRetrieveResponse>;
}

export type AutofillCollectionType = AutofillCollection<
  AutofillSuggestionResponse,
  AutofillRetrieveResponse
>;

/**
 * Underlying collection object class returned by the {@link autofill} function.
 *
 * @class AutofillCollection
 */
export class AutofillCollection<
  AutofillSuggestionResponse,
  AutofillRetrieveResponse
> extends Evented<
  EventTypes<AutofillSuggestionResponse, AutofillRetrieveResponse>
> {
  instances: AutofillInstance[] = [];
  #currentInputs: HTMLInputElement[];

  #autofill = new MapboxAutofill();

  constructor({
    accessToken,
    options,
    theme,
    popoverOptions,
    confirmOnBrowserAutofill
  }: AutofillCollectionOptions) {
    super();

    // Setup browser autofill detection
    initDetectBrowserAutofill();
    window.addEventListener('browserautofill', this.#handleBrowserAutofill);

    config.autofillSessionEnabled = true;

    this.accessToken = accessToken || config.accessToken;
    options && (this.options = options);
    theme && (this.theme = theme);
    popoverOptions && (this.popoverOptions = popoverOptions);
    confirmOnBrowserAutofill &&
      (this.confirmOnBrowserAutofill = confirmOnBrowserAutofill);
    this.update();
  }

  /**
   * The [Mapbox access token](https://docs.mapbox.com/help/glossary/access-token/) to use for all requests.
   *
   * @example
   * ```typescript
   * autofill.accessToken = 'pk.my-mapbox-access-token';
   * ```
   */
  get accessToken(): string {
    return this.#autofill.accessToken;
  }
  set accessToken(newToken: string) {
    this.#autofill.accessToken = newToken;
  }

  #options: Partial<AutofillOptions>;

  /**
   * Options to pass to the underlying {@link MapboxAutofill} interface.
   *
   * @example
   * ```typescript
   * autofill.options = {
   *  language: 'en',
   *  country: 'US',
   * };
   * ```
   */
  get options(): Partial<AutofillOptions> {
    return this.#options;
  }
  set options(newOptions: Partial<AutofillOptions>) {
    this.#options = { ...this.#options, ...newOptions };
    this.instances.forEach((instance) => {
      instance.listbox.options = { ...instance.listbox.options, ...newOptions };
    });
  }

  #theme: Theme;

  /**
   * The {@link Theme} to use for styling the autofill component.
   *
   * @example
   * ```typescript
   * autofill.theme = {
   *   variables: {
   *     colorPrimary: 'myBrandRed'
   *   }
   * };
   * ```
   */
  get theme(): Theme {
    return this.#theme;
  }
  set theme(newTheme: Theme) {
    this.#theme = newTheme;
    this.instances.forEach((instance) => {
      instance.listbox.theme = newTheme;
    });
  }

  #popoverOptions: Partial<PopoverOptions>;

  /**
   * The {@link PopoverOptions} to define popover positioning.
   *
   * @example
   * ```typescript
   * autofill.popoverOptions = {
   *   placement: 'top-start',
   *   flip: true,
   *   offset: 5
   * };
   * ```
   */
  get popoverOptions(): Partial<PopoverOptions> {
    return this.#popoverOptions;
  }
  set popoverOptions(newOptions: Partial<PopoverOptions>) {
    this.#popoverOptions = newOptions;
    this.instances.forEach((instance) => {
      instance.listbox.popoverOptions = newOptions;
    });
  }

  /**
   * If true, forms autofilled by the browser will prompt the
   * {@link confirmAddress} dialog for user confirmation.
   * An {@link AddressConfirmOptions} object can also be passed
   * to prompt {@link confirmAddress} with custom options.
   * Defaults to false.
   *
   * @name confirmOnBrowserAutofill
   * @instance
   * @memberof AutofillCollection
   * @type {boolean | AddressConfirmOptions}
   * @example
   * ```typescript
   * autofill.confirmOnBrowserAutofill = {
   *   minimap: true,
   *   skipConfirmModal: (feature) =>
   *     ['exact', 'high'].includes(
   *       feature.properties.match_code.confidence
   *     )
   * };
   * ```
   */
  confirmOnBrowserAutofill: boolean | AddressConfirmOptions = false;

  /** @section {Methods} */

  /**
   * Updates autofill collection based on the current DOM state.
   * @example
   * ```typescript
   * collection.update();
   * ```
   */
  update(): void {
    // STEP 0: Remove and clean up any existing autofill instances
    this.instances.forEach((instance) => {
      instance.remove();
    });
    // STEP 1: Find the input element(s)
    this.#currentInputs = findAddressInputs();
    // STEP 2: Create a new autofill instance for each input
    this.instances = [];
    this.#currentInputs.forEach((input) => {
      const autofillInstance = new AutofillInstance(
        this,
        input,
        this.#autofill
      );
      autofillInstance.listbox.options = this.options;
      autofillInstance.listbox.theme = this.theme;
      autofillInstance.listbox.popoverOptions = this.popoverOptions;
      this.instances.push(autofillInstance);
    });
  }

  // TODO: optimize this!
  // Called when content changes.
  #handleObserve = (): void => {
    // TODO: add test to make sure this comparison works
    if (!deepEquals(findAddressInputs(), this.#currentInputs)) {
      this.update();
    }
  };

  #observer = new MutationObserver(this.#handleObserve);

  /**
   * Listen for changes to the DOM, and update autofill instances when autofill-able inputs are added/removed.
   *
   * **IMPORTANT:** For performance reasons, it is recommended to carefully control
   * when this is called and to call {@link AutofillCollection#unobserve} when finished.
   *
   * @example
   * ```typescript
   * collection.observe();
   * ```
   */
  observe(): void {
    // Setup observer handler.
    this.#observer.observe(document, {
      subtree: true,
      childList: true
    });

    this.#handleObserve();
  }

  /**
   * Stop listening for changes to the DOM. This only has an effect if called
   * after {@link AutofillCollection#observe}.
   *
   * @example
   * ```typescript
   * collection.unobserve();
   * ```
   */
  unobserve(): void {
    this.#observer.disconnect();
  }

  /**
   * Removes all autofill instances and listeners in the document.
   *
   * @example
   * ```typescript
   * collection.remove();
   * ```
   */
  remove(): void {
    this.instances.forEach((instance) => {
      instance.remove();
    });
    this.unobserve();
    window.removeEventListener('browserautofill', this.#handleBrowserAutofill);
  }

  #handleBrowserAutofill = async (e: CustomEvent): Promise<void> => {
    // Hide listbox, if showing
    this.instances.forEach((instance) => instance.listbox.blur());

    // Address confirmation
    for (const instance of this.instances) {
      const input = instance.listbox.input;
      await tryConfirmBrowserAutofill(
        input,
        e,
        this.confirmOnBrowserAutofill,
        this.accessToken
      );
    }
  };
}

/**
 * Entry point for Mapbox Address Autofill, for use on standard HTML input elements.
 *
 * Compared to {@link MapboxAddressAutofill}, this function automatically attaches
 * to eligible [`<input>`](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/text) elements in-place.
 *
 * You must have a [Mapbox access token](https://www.mapbox.com/help/create-api-access-token/).
 *
 * Eligible inputs must be a descendant of a [`<form>`](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/form) element, and the form
 * must have inputs with proper HTML `autocomplete` attributes. The input itself must be of autocomplete `"street-address"` or `"address-line1""`.
 *
 * If your application works with browser autofill, you may already have this functionality.
 * - [The HTML autocomplete attribute](https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/autocomplete)
 * - [Autofill](https://web.dev/learn/forms/autofill/)
 *
 * @param optionsArg - {@link AutofillCollectionOptions} Object defining options for Autofill search behavior and UI.
 * @example
 * <input type="text" autocomplete="street-address" />
 * <script>
 * mapboxsearch.autofill({
 *   accessToken: 'pk.my.token',
 *   options: { country: 'us' }
 * };
 * </script>
 * @example
 * ```typescript
 * const collection = autofill({
 *   accessToken: 'pk.my.token',
 *   options
 * })
 *
 * myClientSideRouter.on('route', () => collection.update());
 * ```
 */
export function autofill(
  optionsArg: AutofillCollectionOptions
): AutofillCollectionType {
  return new AutofillCollection(optionsArg);
}
